<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data' />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">

        <!--begin::merchantCurrencies-->
        <div class="card card-custom gutter-b" v-if="!merchantIsLoading">
          <div class="card-body">
            <div class="row">
              <!--begin::Item-->
              <div class="col-xl-3 col-md-4 col-sm-6 col-12" v-for="(item, idx) in merchantCurrencies" :key="`merchantCurrencies-${idx}`">
                <div class="card card-custom bg-secondary card-stretch gutter-b">
                  <div class="card-body">
                    <!--begin::Icon-->
                    <div class="media position-absolute top-0 right-0 mr-5 mt-2">
                      <img :src="item.icon" height="25" width="25" :alt="item.code">
                    </div>
                    <!--end::Icon-->
                    <span class="d-block text-dark mt-2">
                      {{ item.name }}({{ item.code | uppercase }})
                    </span>
                    <span class="font-weight-bolder text-dark mb-0 mt-2 d-block"><span class="font-weight-normal">{{ $t('finance.balance') }}</span>: {{ item.balance }}</span>
                  </div>
                </div>
              </div>
              <!--end::Item-->
            </div>
          </div>
        </div>
        <!--end::merchantCurrencies-->

        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('branch_dash.last_transactions_title') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Button-->
              <router-link :to="{ name: 'BranchDashboardNew'}" class="btn btn-primary font-weight-bolder">
                <span class="svg-icon svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <circle fill="#000000" cx="9" cy="15" r="6" />
                      <path
                        d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon--> </span
                >{{ $t('branch_dash.new_transaction') }}</router-link>
              <!--end::Button-->
            </div>
          </div>
          <div class='card-body'>
            <div v-if="isLoading">
              <div class="text-center">{{ $t('commons.data_loading') }}...</div>
            </div>
            <div class="table-responsive" v-else>
              <table class="table">
                  <thead>
                      <tr class="text-muted">
                          <th scope="col">{{ $t('branch_dash.transaction_no') }}</th>
                          <th scope="col">{{ $t('branch_dash.customer') }}</th>
                          <th scope="col">{{ $t('branch_dash.kind') }}</th>
                          <th scope="col">{{ $t('branch_dash.price') }}</th>
                          <th scope="col">{{ $t('branch_dash.quantity') }}</th>
                          <th scope="col">{{ $t('branch_dash.amount') }}</th>
                          <th scope="col">{{ $t('branch_dash.state') }}</th>
                          <th scope="col"></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-if="transactions.length === 0">
                        <td colspan="7" class="text-center">{{ $t('commons.no_data_available') }}</td>
                      </tr>
                      <tr v-for="(item, index) in transactions" :key="'transactions-' + index">
                        <td>{{ item.order_id }}</td>
                        <td>{{ item.fullname }}</td>
                        <td>{{ (item.type) ? $t(`service.${item.type}`) : item.type }}</td>
                        <td>{{ item.price }}</td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.total }}</td>
                        <td>{{ (item.status) ? $t(`service.${item.status}`) : item.status }}</td>
                        <td>
                          <div class="d-flex justify-content-end flex-shrink-0">
                            <button @click.prevent="downloadItem(item.id)" class="btn btn-icon btn-clean btn-bg-light btn-sm" :title="$t('commons.download')">
                              <i class="far fa-file fs-4"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import API_OLD from '@/api/index_old';

export default {
  name: 'BranchDashboard',
  data() {
    return {
      merchantIsLoading: false
    }
  },
  components: {
    Breadcrumb,
  },
  computed: {
    ...mapState({
      isLoading: state => state.transactions.isLoading,
      transactions: state => state.transactions.lastTransaction,
      permissions: (state) => state.customer.permissions,
      merchantCurrencies: state => state.currencies.merchantCurrencies
    }),
    breadcrumb_data() {
      return {
        title: this.$t('branch_dash.title')
      }
    }
  },
  methods: {
    ...mapActions('transactions', ['GET_LAST_TRANSACTION']),
    ...mapActions('global', ['GET_PDF']),
    downloadItem(id) {
      let data = { url: `${API_OLD.transactions}/${id}/pdf`, label: `${id}-merchant-invoice.pdf` };
      this.GET_PDF(data);
    },
    checkPermission( permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.merchant.index')) {
      this.$router.push('/no-permission')
    }
    this.GET_LAST_TRANSACTION();
    this.merchantIsLoading = true;
    this.$store.dispatch('currencies/GET_MERCHANT_CURRENCIES')
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        this.merchantIsLoading = false;
      })
  }
}
</script>
